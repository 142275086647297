import React, { Fragment, useEffect } from "react"
import LoginForm from "./loginForm"
import GoogleLogin from "./googleLogin"
import Magiclink from "./magicLink"

import { useRouter } from "next/router"
import Loader from "@common/loader"
import OtpForm from "@common/auth/otpForm"
import { useToast } from "@common/toast/toastProvider"
import SeparatorText from "@common/separatorText"
import { LoginTypeEnum, Status } from "@common/types"
import { updateAccountDetails } from "@common/network"
import { Header } from "@common/header/headerUnit"

import ForgotPassword from "@common/auth/forgotPassword"
import { getHomePath } from "@common/lib/navigation"
import { Fields } from "@common/v2/form/types"

interface AuthComponentProps {
  data: any
  loginType: LoginTypeEnum[]
  // status: Status
  configHomePath: string
  contactEmail: string
  site: string
}

interface Config {
  [key: string]: Fields
}
const custIdPlaceholder = "{cust_id}"
// 📌 [TO DO]: Get access to "data" in this component itself. (HINT: cookie accessed RN in server side)
export default function AuthComponent({
  data,
  loginType,
  configHomePath,
  contactEmail,
  site,
}: AuthComponentProps) {
  const router = useRouter()
  const { showToast } = useToast()
  const magicLinkHomePath = getHomePath(custIdPlaceholder, configHomePath)
  const redirectPath = router.query.redirect_path as string

  useEffect(() => {
    //this is the pre-login check. If logged in, user is redirected to dashboard directly
    if (data) {
      if (data.status == Status.Success) {
        if (redirectPath && data?.cust_id) {
          router.push(redirectPath.replace(custIdPlaceholder, data.cust_id))
        } else if (data?.home_path) {
          router.push(data.home_path)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    router?.query?.reset &&
      showToast({
        type: "success",
        message: "Password updated successfully!",
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const configs: Config = {
    [LoginTypeEnum.Login]: {
      account_id: {
        element: "input",
        type: "email",
        label: {
          label: "Work Email",
        },
        validation: { required: true, email: true },
      },
      password: {
        element: "input",
        type: "password",
        label: {
          label: <ForgotPassword contactEmail={contactEmail} />,
        },
        validation: { required: true },
      },
    },
  }

  function handleLoginSubmitResponse(data: any) {
    if (!data) return
    const { status, home_path, cust_id } = data
    if (status == "SUCCESS") {
      let url

      if (home_path) {
        url = router.query.url || home_path
      } else if (cust_id) {
        const homePath = getHomePath(cust_id, configHomePath)
        const redirectUrl = router.query.url as string
        url = redirectUrl || homePath
        updateAccountDetails({ home_path: homePath })
      }
      if (url) router.push(decodeURIComponent(url))
    } else if (status == "OTP_PENDING") {
      const url = new URL(window.location.href)
      url.searchParams.set("otp", "true")
      router.push(url.href)
    }
  }

  if (!data) {
    return (
      <div className="h-full w-full">
        <Loader />
      </div>
    )
  }

  if (router && "query" in router && "otp" in router.query) {
    return <OtpForm onSubmitResponse={handleLoginSubmitResponse} />
  }

  return (
    <>
      {loginType?.map((name, index) => {
        switch (name) {
          case LoginTypeEnum.Login:
            return (
              <Fragment key={index}>
                <div className="my-2">
                  <Header.MainHeader variant="h6" alignment="center">
                    <p className="mb-6">Sign in to your account</p>
                  </Header.MainHeader>
                </div>
                <LoginForm
                  onSubmitResponse={handleLoginSubmitResponse}
                  config={configs[name]}
                />
                {renderSeparator(index)}
              </Fragment>
            )
          case LoginTypeEnum.GoogleLogin:
            return (
              <Fragment key={index}>
                <GoogleLogin configHomePath={configHomePath} site={site} />
                {renderSeparator(index)}
              </Fragment>
            )
          case LoginTypeEnum.Magiclink:
            return (
              <Fragment key={index}>
                <Magiclink
                  onSubmitResponse={handleLoginSubmitResponse}
                  contactEmail={contactEmail}
                  homePath={magicLinkHomePath}
                  redirectPath={redirectPath}
                />
                {renderSeparator(index)}
              </Fragment>
            )
          default:
            return null
        }
      })}
    </>
  )

  function renderSeparator(index: any) {
    return (
      index < loginType.length - 1 && (
        <div className="my-10">
          <SeparatorText />
        </div>
      )
    )
  }
}
