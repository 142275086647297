import React, { useState } from "react"
import { getQuickLogin } from "@common/network"
import Modal from "@common/modal/modal"
import Alert from "@common/alert"
import { Fields, FormState } from "@common/v2/form/types"
import Form from "@common/v2/form"
import { NetworkResponse } from "@common/types"

type MagicLinkProps = {
  contactEmail?: string
  homePath: string
  redirectPath?: string
  onSubmitResponse: (data: any) => void
}
export default function MagicLink({
  onSubmitResponse,
  contactEmail,
  homePath,
  redirectPath,
}: MagicLinkProps) {
  const [showModal, setShowModal] = useState(false)
  const [isError, setIsError] = useState(false)

  function handleSubmitResponse(response: NetworkResponse) {
    setShowModal(true)
    if (isError) {
      setIsError(true)
    }
    if (onSubmitResponse) onSubmitResponse(response?.data)
  }

  const fields: Fields = {
    email: {
      element: "input",
      type: "email",
      label: {
        label: "Work Email",
      },
      validation: { required: true, email: true },
    },
  }
  function submitFunction({ email }: FormState) {
    return getQuickLogin({
      email,
      home_path: homePath,
      redirect_path: redirectPath,
    })
  }
  return (
    <>
      <Form
        fields={fields}
        submitFunction={submitFunction}
        onSubmitResponse={handleSubmitResponse}
      />
      {renderNotifierModal()}
    </>
  )

  function renderNotifierModal() {
    return (
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        crossButton={false}
      >
        <Modal.Layout widthClasses="w-full max-w-md">
          {!isError ? (
            <>
              <div className="flex justify-center opacity-90">
                <img src="./images/landing/mail.svg" />
              </div>
              <div className="text-lg mt-3 font-semibold dark:text-gray-400">
                Thanks, you’ll receive an email with login link.
              </div>
              <div className="text-sm  mt-3 dark:text-gray-400">
                If not, do reach out to{" "}
                <span className="font-semibold">{contactEmail}</span>.
              </div>
            </>
          ) : (
            <div className="px-5 py-10">
              <Alert variant="error">
                Something went wrong, please try again later.
              </Alert>
            </div>
          )}
        </Modal.Layout>
      </Modal>
    )
  }
}
