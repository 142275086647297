import Button from "@common/buttons/button"
import { Header } from "@common/header/headerUnit"
import Modal from "@common/modal/modal"
import { getResetPasswordToken } from "@common/network"
import Form, { Submit } from "@common/v2/form"
import DefaultLayout from "@common/v2/form/layouts/default"
import { Fields } from "@common/v2/form/types"
import { useState } from "react"

export default function ForgotPassword({ contactEmail }: { contactEmail: string }) {
  const resetForm: Fields = {
    email: {
      element: "input",
      type: "email",
      label: {
        label: "Email",
      },
      validation: {
        required: true,
        email: true,
      },
    },
  }
  const [forgotModalOpen, setForgotModalOpen] = useState(false)
  const [forgotSubmitted, setForgotSubmitted] = useState(false)

  function handleForgotClose() {
    setForgotModalOpen(false)
    setForgotSubmitted(false)
  }
  return (
    <>
      {renderForgotModal()}
      <div className="flex items-center justify-between w-full">
        <p>Password *</p>
        <Button
          tabIndex={-1}
          type="button"
          onClick={(e: any) => {
            e.stopPropagation()
            setForgotModalOpen(true)
          }}
          size="xs"
          variant="minimal"
        >
          Forgot your password?
        </Button>
      </div>
    </>
  )

  function renderForgotModal() {
    return (
      <Modal isOpen={forgotModalOpen} onClose={() => handleForgotClose()}>
        <Modal.Layout widthClasses="sm:max-w-lg sm:w-full">
          {forgotSubmitted ? (
            <div className="p-3 text-primary">
              <div className="header-2">
                Thanks, you’ll receive an email with instructions to reset your
                password.
              </div>
              <br />
              <div className="text-sm">If not, do reach out to {contactEmail}.</div>
            </div>
          ) : (
            <>
              <Header alignment="center">
                <Header.MainHeader>Reset your Password</Header.MainHeader>
                <Header.Description>
                  Enter the email address associated with your account and we'll send
                  you a link to reset your password.
                </Header.Description>
              </Header>
              <Form
                fields={resetForm}
                submitFunction={getResetPasswordToken}
                onSubmitSuccess={() => setForgotSubmitted(true)}
              >
                <DefaultLayout />
                <Submit>Continue</Submit>
              </Form>
            </>
          )}
        </Modal.Layout>
      </Modal>
    )
  }
}
