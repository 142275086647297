import { getLogin } from "@common/network"
import Form, { Submit } from "@common/v2/form"
import DefaultLayout from "@common/v2/form/layouts/default"

export default function LoginForm({ onSubmitResponse, config }) {
  function handleSubmitResponse(response) {
    onSubmitResponse?.(response?.data)
  }
  return (
    <>
      <Form
        fields={config}
        submitFunction={getLogin}
        onSubmitResponse={handleSubmitResponse}
      >
        <DefaultLayout />
        <Submit>Sign In</Submit>
        <p className="my-2">
          Don't have an account?{" "}
          <a href="/signup" className="link-blue">
            Signup
          </a>
        </p>
      </Form>
    </>
  )
}
