import { useRouter } from "next/router"
import { useToast } from "@common/toast/toastProvider"
import { verifyOtp, resendOtp } from "@common/network"
import { NetworkResponse, Status } from "@common/types"
import { Fields } from "@common/v2/form/types"
import { Header } from "@common/header/headerUnit"
import Form, { Submit } from "@common/v2/form"
import DefaultLayout from "@common/v2/form/layouts/default"
import { ToastOptionsType } from "@common/toast/toastAdapter"

const fields: Fields = {
  otp: {
    element: "input",
    label: { label: "OTP" },
    validation: {
      required: true,
    },
  },
}
export default function OtpForm({
  onSubmitResponse,
}: {
  onSubmitResponse?: (data: any) => void
}) {
  const { updateToast, showToast } = useToast()
  const router = useRouter()
  const otpString = router.query.otp as string
  const otp = isNaN(parseInt(otpString)) ? undefined : parseInt(otpString)

  return (
    <>
      <Header>
        <Header.MainHeader>Verify your email</Header.MainHeader>
        <Header.Description>
          <div className="space-y-4 py-4">
            <p>
              To continue, please enter the 6-digit verification code sent to your
              email.
            </p>
            <p>
              Didn't receive a code?
              <button
                className="link-blue cursor-pointer"
                onClick={() => handleResendClick()}
              >
                Resend
              </button>
              .
            </p>
          </div>
        </Header.Description>
      </Header>
      <Form
        fields={fields}
        submitFunction={verifyOtp}
        onSubmitResponse={handleSubmitResponse}
        initialFormState={{ otp }}
      >
        <DefaultLayout />
        <Submit>Verify</Submit>
      </Form>

      <p className="py-4">
        Don't have an account?{" "}
        <a href="/signup" className="link-blue">
          Signup
        </a>
      </p>
    </>
  )

  function handleSubmitResponse(response: NetworkResponse) {
    if (onSubmitResponse) onSubmitResponse(response?.data)
  }

  async function handleResendClick() {
    const toastId = showToast({ message: "Resending...", type: "loading" })
    try {
      const { message, status } = await resendOtp()
      if (status === Status.Success) {
        updateToastWithMessage(toastId, "success", "OTP has been resent.")
      } else {
        updateToastWithMessage(toastId, "error", message, false)
      }
    } catch (error) {
      updateToastWithMessage(toastId, "error", "OTP not sent!")
    }
  }

  function updateToastWithMessage(
    toastId: string,
    type: ToastOptionsType["type"],
    message: string,
    autoClose: ToastOptionsType["autoClose"] = 200
  ) {
    const render = typeof message === "string" ? message : <div>{message}</div>
    updateToast(toastId, { type, message: render, autoClose })
  }
}
